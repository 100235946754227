
export const initSubheader = () => {
    let scrollState = 0;
    // let scrollStatePrev = 0;
    let switchState: boolean;
    // let scrollUp = false;

    let windowElement = window;
    let documentElement = document.documentElement;

    if (!windowElement || !documentElement) {
        return;
    }

    let position = window.scrollY;

    if (position <= 120 && (scrollState === 2 || scrollState === 3)) {
        // scrollStatePrev = scrollState;
        scrollState = 1; //back to top menu
        switchState = true;
        // scrollUp = true;
    } else if (position > 120 && position <= 600 && (scrollState === 0 || scrollState === 1 || scrollState === 3)) {
        // if (scrollState === 3) {
            // scrollUp = true;
        // } else {
        //     scrollUp = false;
        // }
        // scrollStatePrev = scrollState;
        scrollState = 2; // fixed menu
        switchState = true;
    } else if (position > 600 && scrollState !== 3) {
        // scrollStatePrev = scrollState;
        scrollState = 3; // fixed menu with CTA
        switchState = true;
    } else {
        switchState = false;
    }

    let body = document.querySelector('body') as HTMLElement;
    if (body && switchState) {
        if (scrollState === 2) {
            body.classList.add('scroll1');
            body.classList.remove('scroll2');
        } else if (scrollState === 1) {
            body.classList.remove('scroll1');
            body.classList.remove('scroll2');
        } else if (scrollState === 3) {
            body.classList.add('scroll2');
            body.classList.remove('scroll1');
        }
    }
}