import '@css/web.scss'

import {Carousel, Popover} from 'bootstrap';
import {initStoreCampaignIdInCookie} from "./lib/cid_links";
import {initSubheader} from "./lib/subheader";
import {initCookieConsent} from "./lib/cookie_consent";
import {initFreeform} from "./lib/freeform";


export class VbpPopover extends Popover {

}

document.addEventListener('DOMContentLoaded', () => {
    initCookieConsent();
    initStoreCampaignIdInCookie();
    initFreeform();

    document.addEventListener('scroll', () => {
        initSubheader();
    });
});

// Initialize all Bootstrap components
document.addEventListener('DOMContentLoaded', () => {
    let carousels = document.querySelectorAll('.carousel') as NodeListOf<HTMLElement>;
    carousels.forEach((carousel) => {
        new Carousel(carousel);
    })

    let popOvers = document.querySelectorAll('.custom-popover') as NodeListOf<HTMLElement>;
    popOvers.forEach((popover) => {
        new Popover(popover);
    })
});

