import {hasCookie, setCookie} from "./cookies";

export const initCookieConsent = () => {
    let cookieBannerButton = document.querySelector('#cookie-banner .btn') as HTMLButtonElement;
    let cookieBanner = document.querySelector('#cookie-banner') as HTMLDivElement;

    cookieBannerButton?.addEventListener('click', () => {
        setCookie("cookie-banner", "geaccepteerd", 365);

        const event= new Event("cookie-consent-closed");
        document.dispatchEvent(event);

        cookieBanner?.classList.remove('d-block');
        cookieBanner?.classList.add('d-none');
    });

    setTimeout(function () {
        if (!hasCookie('cookie-banner') || localStorage.getItem('consent') === null) {
            const event= new Event("cookie-consent-opened");
            document.dispatchEvent(event);

            cookieBanner.classList.remove('d-none');
            cookieBanner.classList.add('d-block');
        }
    }, 150);
}