export const initFreeform = () => {
    preventText();
    showUploadedFileNames();
}

/**
 * Show file names of uploaded files below the file input
 * Used on the proefvertaling and post-editing form
 */
const showUploadedFileNames = () => {
    let fileInputElements = document.querySelectorAll('.form-control-file') as NodeListOf<HTMLInputElement>;

    fileInputElements.forEach((input: HTMLInputElement) => {
        input.addEventListener('change', function(event){
            let trigger = event.target as HTMLInputElement;
            let filenamesElement = trigger.closest('.form-group')?.querySelector('.file-names') as HTMLDivElement;

            let files = input.files

            if (files) {
                let output = Array.from(files).map(f => '<li>' + f.name + '</li>');
                filenamesElement.innerHTML = '<ul>' + output.join('') + '</ul>';
            }
        });
    });
}

/**
 * Prevent text from being entered in date fields
 * Used on date fields by adding a custom class in Freeform
 */
const preventText = () => {
    let preventTextElements = document.querySelectorAll('.datum-voorkom-tekst') as NodeListOf<HTMLInputElement>;

    preventTextElements.forEach((input) => {
        input.addEventListener('keydown', function(event){
            let allowedKeys = [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "-",
                "/",
                ".",
                "Backspace",
                "Delete",
                "Tab",
                "ArrowLeft",
                "ArrowRight",
                "ArrowUp",
                "ArrowDown",
                "Home",
                "End",
                "Enter",
                "NumLock",
                "Numpad0",
                "Numpad1",
                "Numpad2",
                "Numpad3",
                "Numpad4",
                "Numpad5",
                "Numpad6",
                "Numpad7",
                "Numpad8",
                "Numpad9",
                "NumpadSubtract",
                "NumpadDivide",
                "NumpadDecimal",
                "Meta",
            ];

            if (!allowedKeys.includes(event.key)) {
                event.preventDefault();
            }
        });
    })
}