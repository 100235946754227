import {getCookie, setCookie} from "./cookies";

export function initStoreCampaignIdInCookie() {
    const url = new URL(window.location.href);
    const urlParameter = url.searchParams.get('vbp_cid')?.toString();

    let cookieValue = getCookie('vbp_cid') ?? ''

    if (urlParameter) {
        setCookie('vbp_cid', urlParameter);
        cookieValue = urlParameter ?? '';
    }

    if(cookieValue !== ''){
        document
            .querySelectorAll<HTMLInputElement>('input.vbp-cid-value,textarea.vbp-cid-value')
            .forEach((el) => el.value = cookieValue);

        document
            .querySelectorAll<HTMLAnchorElement>('a[href="mailto:info@vertaalbureau-perfect.nl"]')
            .forEach((el) => {
                el.href = 'mailto:offerte@vertaalbureau-perfect.nl?body=Graag voor interne referentie deze regel laten staan. (' + cookieValue + ')';
                el.innerHTML = 'offerte@vertaalbureau-perfect.nl';
            });

        document
            .querySelectorAll<HTMLAnchorElement>('a[href="mailto:info@uebersetzungsbuero-perfekt.de"]')
            .forEach((el) => {
                el.href = 'mailto:anfrage@uebersetzungsbuero-perfekt.de?body=Diese Zeile für interne Referenzzwecke bitte nicht entfernen. (' + cookieValue + ')';
                el.innerHTML = 'anfrage@uebersetzungsbuero-perfekt.de';
            });
    }
}